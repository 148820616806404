
.form-card-header {
    background-color: var(--primary-app-color);
    padding: 1em;
}

.form-card-body {
    background-color: var(--cui-light);
    padding: calc(var(--cui-card-spacer-y) * 2) calc(var(--cui-card-spacer-x) * 3);
}

.form-label, .form-check-label {
    color: var(--cui-gray);
    font-weight: bold;
}

.form-control {
    padding: 0.375rem 1rem;
    border-radius:  var(--cui-border-radius-xl) !important;
    color: var(--cui-gray);
}


.input-group {
    gap: 1em;
}

.input-group-text {
    background-color: var(--cui-body-bg);
    border-radius: var(--cui-border-radius-xl) !important;
    font-weight: bold;
    color: var(--cui-gray);
}



.date-picker-input-group {
    border-radius: var(--cui-border-radius-xl) !important;
}

.date-picker {
    width: 100%;
}


.form-select {
    padding: 0.375rem 1rem;
    border-radius: var(--cui-border-radius-xl) !important;
    color: var(--cui-gray);
}


c-multi-select {
    width: 100%;
}

.form-multi-select-input-group {
    border-radius: var(--cui-border-radius-xl) !important;
    color: var(--cui-gray);
}