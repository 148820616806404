
.loading-overlay-parent {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    background-color: rgba(255, 255, 255, 0.8); 
    z-index: 2; 
    display: flex; 
    justify-content: center;
    align-items: center;
    pointer-events: all; 
  }
  