$button-background-color: #E6E6E6 !default;

.btn:not(.calendar-nav .btn) {
    padding: calc(var(--cui-btn-padding-y) * 0.8) calc(var(--cui-btn-padding-x) * 4);
    border-radius: var(--cui-border-radius-xl);
    border: none;

    &.btn-outline-primary:not(:hover) {
        background-color: $button-background-color;
    }
}

.btn-success {
    color: white;
}


.app-tabs {
    width: 100%;
    border-bottom: 3px solid var(--cui-light);
    padding-left: 1rem;
    c-button-group {
        gap: 3rem;
        width: 100%;

        button:not(.selected) {
            background-color: $button-background-color;
            color: var(--primary-app-color);
            font-weight: 600;
        }

        button {
            flex: 1;
            max-width: 20%;
            border-radius: 0.8rem 0.8rem 0 0 !important; 
        }
    }
}


.header-dropdown {
    background-color: #FFFFFF;
    border-radius: 25px;
    width: 3rem;
    height: 3rem;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    & > button {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

}