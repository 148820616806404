.layout-container {
    margin: auto 0;
    display: block;
    padding: 0 15px;
}


.nav-group-items {
    margin-left: 1.5rem;
}

.nav-title {
    color: #fff !important;
}