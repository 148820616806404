/* You can add global styles to this file, and also import other style files */
// Import CoreUI functions

@import "@coreui/coreui-pro/scss/functions";

// If you want to override variables do it here
@import "variables";
@import "variables-dark";

// Import styles with default layout.
@import "@coreui/coreui-pro/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// Custom styles for this theme
@import "theme";

// Some temp fixes
//@import "fixes";

// If you want to add custom CSS you can put it here.
@import "custom";

@import "table";

@import "container";

@import "loading";

// Examples
// We use those styles to show code examples, you should remove them in your application.
@import "examples";

@import 'node_modules/quill/dist/quill.bubble.css';
@import 'node_modules/quill/dist/quill.snow.css';

.quill-editor {
    height: 200px;
    max-width: 100%;
    width: 100%;
  }

.drop-zone {
    height: 100%;
    border: 2px dashed #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    cursor: pointer;
}


.drop-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 1em
}