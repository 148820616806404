.table-text {
    font-size: 0.8em;
    // overflow-x: auto; 
    white-space: nowrap;
}

.data-table {
    table {
        .thead {
            tr {
                th {
                    background: none;
                    border: none;
                    box-shadow: none;

                    .form-control {
                        border: none;
                        color: var(--cui-gray);
                    }
                }

                &:first-child {
                    th {
                        div {
                            color: var(--primary-app-color);
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border: none;
                    color: var(--cui-gray);
                    font-weight: 600;
                }
            }
        }
    }
}

// ::ng-deep c-smart-table {
//     table {
//       background-color: #f5f5f5; // Cambia el fondo de la tabla
//     }
  
//     th {
//       background-color: #004085; // Color de fondo del encabezado
//       color: white; // Texto blanco
//       font-weight: bold;
//     }
  
//     td {
//       padding: 12px; // Ajusta el padding de las celdas
//     }
//   }
  